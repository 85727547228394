import CircleExclamationIcon from "@/svg/circle-exclamation-solid.svg?react";
import classNames from "classnames";
import React from "react";

export default function Error({className, children}: {
    className?: string,
    children?: React.ReactNode
}) {
    return children && (
        <div className={classNames("flex gap-2 items-center text-error-700 fill-error-700 text-xs", className)}>
            <CircleExclamationIcon className="size-4" />
            {children}
        </div>
    )
}