import { requireNotNull } from "@enymo/ts-nullsafe";
import { produce } from "immer";
import { byId } from "./common";
import { Node } from "./types";

export interface LinkedListNode extends Node {
    previous_id: number | string | null
}

export interface ResourceLinkedListNode {
    id?: number | string,
    previous_id: number | string | null
}

export function getLastLinkedListItem<T extends LinkedListNode>(list: T[]): T | null {
    const nodeWithNextIds = new Set(list.map(({previous_id}) => previous_id));
    return list.find(({id}) => !nodeWithNextIds.has(id)) ?? null;
}

export function sortLinkedList<T extends LinkedListNode>(list: T[]): T[] {
    const result: T[] = [];
    const map = new Map<T["id"], T>(list.map(node => [node.id, node]));
    let currentNode = getLastLinkedListItem(list);
    while (currentNode !== null) {
        result.push(currentNode);
        currentNode = currentNode.previous_id === null ? null : map.get(currentNode.previous_id) ?? null;
    }
    return result.reverse();
}

export async function deleteResourceLinkedListItem<T extends ResourceLinkedListNode>(list: T[], id: T["id"], update: (id: T["id"], update: { previous_id: T["previous_id"] }, updateMethod: "local-only") => Promise<void>, destroy: (id: T["id"]) => Promise<void>) {
    const node = requireNotNull(list.find(node => node.id === id), "Resource linked list deletion node not found");
    const next = list.find(node => node.previous_id === id);

    await destroy(id);

    if (next !== undefined) {
        update(next.id, {
            previous_id: node.previous_id
        }, "local-only");
    }
}

export function deleteLinkedListItem<T extends LinkedListNode>(list: T[], id: T["id"]): T[] {
    return produce(list, draft => {
        const index = draft.findIndex(byId(id));
        if (index === -1) throw new Error("Unable find element to remove");
        const [removed] = draft.splice(index, 1);
        const next = draft.find(({previous_id}) => previous_id === id);
        if (next) {
            next.previous_id = removed.previous_id;
        }
    });
}