import classNames from "classnames";
import React, { useCallback, useState } from "react";

export default function Slider({
    className,
    direction,
    progressClassName,
    progress,
    buffered,
    onChangeProgress
}: {
    className?: string,
    direction: "horizontal" | "vertical",
    progressClassName?: string,
    progress: number,
    buffered?: {
        start: number,
        length: number
    }[],
    onChangeProgress: (progress: number) => void
}) {
    const [draggedProgress, setDraggedProgress] = useState<number | null>(null);
    const displayedProgress = `${(draggedProgress ?? progress) * 100}%`;

    const handleDrag = useCallback<React.PointerEventHandler>(e => {
        setDraggedProgress(Math.min(1, Math.max(0, direction === "horizontal" ? e.nativeEvent.offsetX / e.currentTarget.clientWidth : 1 - e.nativeEvent.offsetY / e.currentTarget.clientHeight)));
    }, [setDraggedProgress, direction]);

    const handlePointerDown = useCallback<React.PointerEventHandler>(e => {
        e.currentTarget.setPointerCapture(e.pointerId);
        if (e.currentTarget === e.target) {
            handleDrag(e);
        }
        else {
            setDraggedProgress(progress);
        }
    }, [handleDrag, progress, setDraggedProgress]);

    const handlePointerMove = useCallback<React.PointerEventHandler>(e => {
        if (draggedProgress !== null) {
            handleDrag(e);
        }
    }, [draggedProgress, handleDrag]);

    const handlePointerUp = useCallback<React.PointerEventHandler>(e => {
        if (draggedProgress !== null) {
            onChangeProgress(draggedProgress);
            setDraggedProgress(null);
        }
    }, [draggedProgress, onChangeProgress, setDraggedProgress]);

    return (
        <div 
            className={classNames(`relative group/slider transition-transform rounded-sm bg-neutral-500/50 cursor-pointer ${direction === "horizontal" ? "hover:scale-y-125 h-1.5" : "hover:scale-x-125 w-1.5"}`, className)}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerMove={handlePointerMove}
            draggable={false}
        >
            <div className={`absolute size-3 shadow-knob transition-transform bg-primary-900 rounded-full -translate-x-1/2 z-20 ${direction === "horizontal" ? "group-hover/slider:scale-x-125 -translate-y-1/2 top-1/2" : "group-hover/slider:scale-y-125 translate-y-1/2 left-1/2"}`} style={{
                [direction === "horizontal" ? "left" : "bottom"]: displayedProgress
            }} />
            <div className={classNames(`pointer-events-none absolute left-0 bottom-0 z-10 rounded-sm ${direction === "horizontal" ? "h-full" : "w-full"}`, progressClassName)} style={{
                [direction === "horizontal" ? "width" : "height"]: displayedProgress
            }} />
            {buffered?.map(({start, length}, index) => (
                <div key={index} className={`pointer-events-none absolute rounded-sm bg-neutral-700/50 ${direction === "horizontal" ? "h-full" : "w-full"}`} style={{
                    [direction === "horizontal" ? "left" : "bottom"]: `${start * 100}%`,
                    [direction === "horizontal" ? "width" : "height"]: `${length * 100}%`
                }} />
            ))}
        </div>
    )
}