import { GlissadeInput, GlissadeInputProps, useGlissadeInput } from "@enymo/glissade";
import classNames from "classnames";
import React from "react";
import Error from "../Error";

export interface InputProps extends Omit<GlissadeInputProps, "choices" | "type"> {
    label?: string,
    error?: string,
    type?: Exclude<GlissadeInputProps["type"], "select">
}

export default function Input({
    disabled,
    className,
    name,
    label,
    error: errorProp,
    ...props
}: InputProps) {
    const {error, ...glissade} = useGlissadeInput({disabled, name, error: errorProp});

    return (
        <div className={classNames("flex flex-col", className)}>
            {label && <label className="body-m-md mb-3">{label}</label>}
            <GlissadeInput
                name={name}
                className={`border rounded-lg bg-bg-200 px-3.5 text-base text-text-900 placeholder:text-text-300 ${error ? "border-error-500" : "border-neutral-200 hover:border-neutral-300 focus:border-primary-500"}`}
                inputClassName="h-11"
                textareaClassName="py-2.5"
                {...glissade}
                {...props}
            />
            <Error className="mt-2.5">{error}</Error>
        </div>
    )
}