import { createRequiredContext } from "@enymo/react-better-context";
import axios from "axios";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { route } from "ziggy-js";
import { socket } from "../common";
import { useSentryUser } from "../main/ErrorBoundary";
import { User, useUsers } from "../resources";

type Login = (email: string, password: string, remember: boolean, resetToken?: string) => Promise<User>;
type Update = (update: {
    current_password?: string,
    password?: string
}) => Promise<void>
type SignUp = (email: string, password: string) => Promise<void>;

const [Provider, useUser] = createRequiredContext<{
    user: User | null,
    login: Login,
    update: Update,
    signUp: SignUp,
    requestVerifyEmail: () => Promise<void>,
    logout: () => void,
    loading: boolean
}>("UserProvider must be present in component tree");

export { useUser };
export default function UserProvider({children}: {
    children: React.ReactNode
}) {
    const {i18n} = useTranslation();
    const [user, {update: updateUser, query, destroy, loading}] = useUsers({id: "me" as any});
    const [,setUser] = useSentryUser();

    const login = useCallback<Login>(async (email, password, remember, resetToken) => {
        const response = await axios.post(route(resetToken === undefined ? "users.login" : "users.reset-password"), {email, password, remember, token: resetToken});
        updateUser(response.data.data, "local-only");
        return response.data.data;
    }, [query]);

    const update = useCallback<Update>((update) => updateUser(update), [updateUser]);

    const signUp = useCallback<SignUp>((email, password) => query("store", {email, password}, {locale: i18n.language}), [query, i18n.language]);

    const requestVerifyEmail = useCallback(async () => {
        await axios.post(route("users.request-verify-email", {locale: i18n.language}));
    }, [i18n.language]);

    const logout = useCallback(() => {
        axios.post(route("users.logout"));
        destroy("local-only");
    }, [destroy]);

    useEffect(() => {
        socket.disconnect();
        socket.connect();
        setUser?.(user?.email ?? null);
    }, [user, setUser]);

    return (
        <Provider value={{
            user,
            login,
            update,
            signUp,
            requestVerifyEmail,
            logout,
            loading
        }}>
            {children}
        </Provider>
    )
}