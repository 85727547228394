import { createContext } from "@enymo/react-better-context";
import globalDayjs, { ConfigType, Dayjs } from "dayjs";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

type DayjsBuilder = (date?: ConfigType) => Dayjs;

const [Provider, useDayjs] = createContext<DayjsBuilder>(globalDayjs);

export { useDayjs };
export default function DayjsProvider({children}: {
    children: React.ReactNode
}) {
    const {i18n} = useTranslation();
    const dayjs = useCallback<DayjsBuilder>(date => globalDayjs(date).locale(i18n.language), [i18n.language]);

    return (
        <Provider value={dayjs}>
            {children}
        </Provider>
    )
}