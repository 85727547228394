import React from "react"

export default function LinebreakText({children}: {
    children: string
}) {
    return <>{children.split("\n").map((line, index) => (
        <React.Fragment key={index}>
            {index > 0 && <br />}
            {line}
        </React.Fragment>
    ))}</>
}