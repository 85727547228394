import { WebResourceProvider } from "@enymo/react-resource-hook-web";
import { SocketProvider } from "@enymo/react-socket-hook";
import axios from "axios";
import React from "react";
import { route } from "ziggy-js";
import { socket } from "../common";
import DayjsProvider from "./DayjsProvider";
import UserProvider from "./UserProvider";

export default function CommonProvider({children}: {
    children: React.ReactNode
}) {
    return (
        <DayjsProvider>
            <SocketProvider value={socket}>
                <WebResourceProvider value={{axios, routeFunction: route}}>
                    <UserProvider>
                        {children}
                    </UserProvider>
                </WebResourceProvider>
            </SocketProvider>
        </DayjsProvider>
    )
}