import createResourceFactory from "@enymo/react-resource-hook";
import createWebResourceAdapter from "@enymo/react-resource-hook-web";
import { toISOStringWithTimezone } from "./common";
import { QuillDelta } from "./richText";
import { GenitalAreaType, Locale, Nationality, Preference, Rating, Resolution, Zodiac } from "./types";

const createResource = createResourceFactory({
    adapter: createWebResourceAdapter({})
});

export const roles = <const>["user", "permanentAccess", "admin"];
export type Role = typeof roles[number];

export interface Subscription {
    plan: Plan | null,
    valid_from: Date
}
export interface User {
    id: number,
    epoch_id: string,
    name: string,
    email: string,
    role: Role,
    created_at: Date,
    email_verified_at: Date | null,
    plan?: Plan,
    next_subscription: Subscription | null
}

export interface ApiUser extends Omit<User, "next_subscription" | "created_at" | "email_verified_at"> {
    next_subscription: Omit<Subscription, "valid_from"> & {
        valid_from: string
    } | null,
    created_at: string,
    email_verified_at: string | null
}

export interface UserUpdate {
    name: string,
    email: string,
    role: Role,
    current_password: string,
    password: string
}

interface Pagination {
    current_page: number,
    from: number,
    last_page: number,
    path: string,
    per_page: number,
    to: number,
    total: number
}

export const [useUsers] = createResource<User, UserUpdate, Pagination>("users", {
    transformer: ({
        created_at,
        email_verified_at,
        next_subscription,
        ...user
    }: ApiUser): User => ({
        created_at: new Date(created_at),
        email_verified_at: email_verified_at !== null ? new Date(email_verified_at) : null,
        next_subscription: next_subscription !== null ? {
            ...next_subscription,
            valid_from: new Date(next_subscription.valid_from)
        } : null,
        ...user
    }),
    inverseTransformer: ({
        created_at,
        email_verified_at,
        next_subscription,
        ...user
    }: User): Partial<ApiUser> => ({
        created_at: toISOStringWithTimezone(created_at),
        email_verified_at: toISOStringWithTimezone(email_verified_at),
        ...user
    })
});

export interface Plan {
    id: number,
    name: string
}

export interface UserUpdate extends User {
    password: string
}

export interface Image {
    id: number,
    width: number,
    height: number,
    src: string
}

export interface Model {
    id: number,
    first_name: string,
    last_name?: string | null,
    description: string,
    age: number,
    gender: "male" | "female",
    weight: number,
    height: number,
    cup_size: string, 
    nationality: Nationality,
    zodiac: Zodiac,
    genital_area: GenitalAreaType,
    likes: number,
    dislikes: number,
    rating: Rating,
    favorite: boolean,
    bio: string,
    preferences: Preference[],
    created_at: Date,

    translations?: Partial<Record<Locale, {
        description: string,
        bio: string,
        keywords: string[]
    }>>,
    preview?: Image,
    preview_thumbnail?: Image,
    avatar?: Image,
    imageSets?: Pick<ImageSet, "id" | "title" | "featured_preview" | "featured_model" | "additional_previews" | "images_count" | "publish_at" | "likes">[],
    videos?: Pick<Video, "id" | "publish_at" | "featured_model" | "featured_preview" | "likes" | "title" | "duration">[]
}

export interface ApiModel extends Omit<Model, "imageSets" | "videos"> {
    imageSets?: (Omit<NonNullable<Model["imageSets"]>[number], "publish_at" | "unpublished_at"> & {
        publish_at: string,
        unpublish_at: string
    })[],
    videos?: (Omit<NonNullable<Model["videos"]>[number], "publish_at" | "unpublished_at"> & {
        publish_at: string,
        unpublish_at: string
    })[]
}

export interface ModelUpdate extends Omit<Model, "preview_image" | "avatar" | "imageSets" | "videos" | "description" | "bio" | "likes" | "dislikes" | "translations"> {
    avatar: File,
    preview_image: File,
    translations: Partial<Record<Locale, {
        description: string,
        bio: string,
        keywords: string[]
    } | null>>
}

export const [useModels] = createResource<Model, ModelUpdate, Pagination>("models", {
    transformer: ({
        videos,
        imageSets,
        ...model
    }: ApiModel) => ({
        videos: videos?.map(({
            publish_at,
            unpublish_at,
            ...video
        }) => ({
            publish_at: new Date(publish_at),
            unpublish_at: new Date(unpublish_at),
            ...video
        })),
        imageSets: imageSets?.map(({
            publish_at,
            unpublish_at,
            ...imageSet
        }) => ({
            publish_at: new Date(publish_at),
            unpublish_at: new Date(unpublish_at),
            ...imageSet
        })),
        ...model
    })
})

export interface VideoResolution {
    id: number,
    resolution: Resolution,
    url: string,
    type: "source" | "derived",
    status: "pending" | "processing" | "error" | "done",
    filesize: number,
    duration: number,
    thumbnail?: Image
}

export interface VideoResolutionUpdate {
    file: File
}

export const [useVideoResolutions] = createResource<VideoResolution, VideoResolutionUpdate>("videos.resolutions");

export interface VideoPreview {
    id: number,
    previous_id: number | null,
    thumbnail: Omit<Image, "id">,
    content: Omit<Image, "id"> | null,
    created_at: Date,
    filename?: string
}

export interface VideoPreviewUpdate {
    previous_id: number | null,
    content: File
}

export const [useVideoPreviews] = createResource<VideoPreview, VideoPreviewUpdate>("videos.previews");

export type Translations = Partial<Record<Locale, {
    title: string,
    description: string,
    keywords: string[]
}>>;
export type NullableTranslations = Partial<Record<Locale, {
    title: string,
    description: string,
    keywords: string[]
} | null>>;

export interface Video {
    id: number,
    category_id: number,
    title: string,
    description: string,
    likes: number,
    dislikes: number,
    publish_at: Date | null,
    rating: Rating,
    favorite: boolean,
    downloadable: boolean,
    watch_later: boolean,
    view: boolean,
    highest_resolution: Resolution,
    unpublished_at: Date | null,
    status: "incomplete" | "processing" | "error" | "ok",
    duration: number,

    translations?: Translations,
    category?: Pick<Category, "title">,
    featured_model?: Pick<Model, "id" | "first_name" | "age" | "avatar">,
    models?: Pick<Model, "id" | "first_name" | "age" | "avatar">[],
    featured_preview?: VideoPreview,
    previews?: VideoPreview[],
    resolutions?: Omit<VideoResolution, "duration" | "thumbnail">[]
}

export interface VideoUpdate {
    publish_at: Date,
    unpublished: boolean,
    models: number[],
    category_id: number,
    translations: NullableTranslations,
    rating: Rating,
    favorite: boolean,
    view: true
}

export const [useVideos] = createResource<Video, VideoUpdate, Pagination>("videos", {
    transformDates: true
});

export type ImageSetUpdate = VideoUpdate;
export interface Category {
    id: number,
    previous_id: number | null,
    title: string,
    description: string,

    translations?: Partial<Record<Locale, {
        title: string,
        description: string
    }>>,
    logo?: Image,
    preview?: Image
}

export interface CategoryUpdate extends Omit<Category, "id" | "title" | "description" | "logo" | "preview" | "translations"> {
    logo: File,
    preview: File,
    translations: Partial<Record<Locale, {
        title: string,
        description: string
    } | null>>
}

export const [useCategories] = createResource<Category, CategoryUpdate>("categories", {
    transformDates: true
});

export interface ImageSetImage {
    id: number,
    previous_id: number | null,
    filename?: string,
    thumbnail: Omit<Image, "id">,
    content?: Omit<Image, "id">,
    created_at: Date
}

export interface ImageSetImageUpdate {
    previous_id: number | null,
    content: File
}

export const [useImageSetImages] = createResource<ImageSetImage, ImageSetImageUpdate>("image-sets.images");

export interface ImageSet {
    id: number,
    category_id: number,
    title: string,
    images_count: number,
    description: string,
    publish_at: Date | null,
    likes: number,
    dislikes: number,
    rating: Rating,
    favorite: boolean,
    downloadable: boolean,
    has_download: boolean,
    view: boolean,
    unpublished_at: Date | null,

    translations?: Translations,
    featured_preview?: ImageSetImage,
    additional_previews?: ImageSetImage[],
    images?: ImageSetImage[],
    category?: Pick<Category, "title">,
    featured_model?: Pick<Model, "id" | "first_name" | "age" | "avatar">,
    models?: Pick<Model, "id" | "first_name" | "age" | "avatar">[]
}

export const [useImageSets] = createResource<ImageSet, ImageSetUpdate, Pagination>("image-sets", {
    transformDates: true
});

export interface Offer {
    id: number,
    period: "monthly" | "yearly" | "biyearly",
    price: number,
    trial_days?: number,
    trial_price?: number,
    highlight: boolean,
    features?: ("downloads")[],
    url?: string
}

export const staticPageIds = <const>["privacy", "tos", "2257", "faq"];
export type StaticPageId = typeof staticPageIds[number];
export interface StaticPage {
    id: StaticPageId,
    text: QuillDelta[],
    translations?: Partial<Record<Locale, {
        text: QuillDelta[]
    }>>
}

export const [useStaticPages] = createResource<StaticPage, StaticPageUpdate>("static-pages");

export interface BannerImage extends Image {
    previous_id: number | null,
    position: "top" | "bottom",
    locale: Locale,
    filename: string,
    created_at: Date
}

export interface BannerImageUpdate {
    previous_id: number | null,
    position: "top" | "bottom",
    locale: Locale,
    file: File
}

export const [useBannerImages] = createResource<BannerImage, BannerImageUpdate>("banner-images", {
    transformDates: true
});

export type StaticPageUpdate = Pick<StaticPage, "translations">;

export interface Plan {
    id: number,
    previous_id: number | null,
    sid: string,
    users_count: number,
    name: string,
    is_active: boolean,
    visibility: "public" | "unlisted",
    period: "monthly" | "yearly",
    price: number,
    trial_days: number | null,
    trial_price: number | null,
    features: ("downloads")[],
    highlight: boolean,
    deleted_at: Date | null,
    created_at: Date
}

export interface PlanUpdate extends Omit<Plan, "id" | "sid" | "users_count" | "created_at" | "deleted_at"> {
    delete: boolean
}

export const [usePlans] = createResource<Plan, PlanUpdate>("plans", {
    transformDates: true
});