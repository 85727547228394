import { formatDuration } from "@/js/common";
import { Resolution } from "@/js/types";
import CompressIcon from "@/svg/compress-sharp-regular.svg?react";
import ExpandIcon from "@/svg/expand-sharp-regular.svg?react";
import GearIcon from "@/svg/gear-sharp-regular.svg?react";
import PauseIcon from "@/svg/pause-solid.svg?react";
import PlayIcon from "@/svg/play-solid.svg?react";
import VolumeIcon from "@/svg/volume-sharp-regular.svg?react";
import VolumeXMarkIcon from "@/svg/volume-xmark-regular.svg?react";
import useOnClickOutside from "@enymo/react-click-outside-hook";
import classNames from "classnames";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Slider from "./Slider";

function ResolutionSelector({className, values, value, onChange}: {
    className?: string,
    values: Resolution[],
    value: Resolution,
    onChange: (value: Resolution) => void,
}) {
    const {t} = useTranslation();
    const [open, setOpen] = useState(false);
    const ref = useOnClickOutside<HTMLDivElement>(() => setOpen(false), [setOpen]);

    const handleChange = (value: Resolution) => () => {
        setOpen(false);
        onChange(value);
    }

    return (
        <div ref={ref} className={classNames("relative flex items-center", className)}>
            <button type="button" onClick={() => setOpen(!open)} className={open ? "fill-primary-800" : "fill-white hover:fill-primary-900"}>
                <GearIcon className="h-4" />
            </button>
            {open && (
                <div className="absolute -right-2 bottom-[calc(100%+13px)] rounded-md py-1.5 bg-neutral-200/70 flex flex-col">
                    {values.map(resolution => (
                        <button key={resolution} type="button" onClick={handleChange(resolution)} className={`whitespace-nowrap h-4.5 text-left px-3 text-2xs ${resolution === value ? "bg-neutral-200/40 text-text-800" : "text-text-600 hover:text-text-700"}`}>
                            {t(`resolution.${resolution}`)}
                        </button>
                    ))}
                </div>
            )}
        </div>
    )
}

export default function VideoPlayer({className, src, resolutions, resolution, onChangeResolution}: {
    className?: string,
    src: string,
    resolutions?: Resolution[],
    resolution?: Resolution,
    onChangeResolution?: (resolution: Resolution) => void
}) {
    const playerRef = useRef<HTMLDivElement>(null);
    const videoRef = useRef<HTMLVideoElement>(null);
    
    const [fullscreen, setFullscreen] = useState(false);
    const [playing, setPlaying] = useState(false);
    const [duration, setDuration] = useState<number>();
    const [buffered, setBuffered] = useState<TimeRanges>();
    const [currentTime, setCurrentTime] = useState(0);
    const [muted, setMuted] = useState(false);
    const [volume, setVolume] = useState(0.3);

    const bufferedMapped = useMemo(() => buffered !== undefined && duration !== undefined ? Array<void>(buffered.length).fill().map((_, index) => ({
        start: buffered.start(index) / duration,
        length: (buffered.end(index) - buffered.start(index)) / duration
    })) : undefined, [buffered, duration]);

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.currentTime = currentTime;
            videoRef.current.play().catch(() => {});
        }
    }, [src, videoRef])

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.volume = 0.3;
        }
    }, [videoRef]);

    const handleLoadedMetadata = useCallback<React.ReactEventHandler<HTMLVideoElement>>(e => {
        setDuration(e.currentTarget.duration);
    }, [setDuration]);

    const handleBuffering = useCallback<React.ReactEventHandler<HTMLVideoElement>>(e => {
        setBuffered(e.currentTarget.buffered);
    }, [setBuffered]);

    const handleTimeUpdate = useCallback<React.ReactEventHandler<HTMLVideoElement>>(e => {
        setCurrentTime(e.currentTarget.currentTime);
    }, [setCurrentTime]);

    const handleSeek = useCallback((seek: number) => {
        if (videoRef.current && duration !== undefined) {
            const time = seek * duration;
            setCurrentTime(time);
            videoRef.current.currentTime = time;
        }
    }, [videoRef, duration]);

    const handleTogglePlay = useCallback(() => {
        if (videoRef.current?.paused) {
            videoRef.current.play().catch(() => {});
        }
        else {
            videoRef.current?.pause();
        }
    }, [videoRef]);

    const handleToggleFullscreen = useCallback(() => {
        if (fullscreen) {
            document.exitFullscreen();
            setFullscreen(false);
        }
        else {
            playerRef.current?.requestFullscreen();
            setFullscreen(true);
        }
    }, [setFullscreen, fullscreen, playerRef]);

    const handleSetVolume = useCallback((volume: number) => {
        if (videoRef.current) {
            videoRef.current.volume = volume;
            setVolume(volume);
            setMuted(false);
        }
    }, [videoRef, setVolume, setMuted]);

    return (
        <div ref={playerRef} className={classNames("relative group bg-black", className)}>
            <video
                className="size-full"
                ref={videoRef}
                src={src}
                onLoadedMetadata={handleLoadedMetadata}
                onProgress={handleBuffering}
                onTimeUpdate={handleTimeUpdate}
                onPlay={() => setPlaying(true)}
                onPause={() => setPlaying(false)}
                onClick={handleTogglePlay}
                muted={muted}
            />
            {bufferedMapped !== undefined && duration !== undefined && (
                <div className="transition-opacity opacity-0 group-hover:opacity-100 z-10 absolute inset-x-2.5 bottom-2.5 rounded-md bg-neutral-100/70 flex items-center h-8 px-4 gap-4 fill-white">
                    <button type="button" onClick={handleTogglePlay} className="hover:fill-primary-900">
                        {playing ? <PauseIcon className="h-4 w-3" /> : <PlayIcon className="h-4 w-3" />}
                    </button>
                    <Slider
                        className="grow"
                        direction="horizontal"
                        progressClassName="bg-primary-600"
                        progress={currentTime / duration}
                        onChangeProgress={handleSeek}
                        buffered={bufferedMapped}
                    />
                    <span className="text-2xs text-text-700">
                        {formatDuration(currentTime)} / {formatDuration(duration)}
                    </span>
                    <div className="relative group/volume hover:fill-primary-900 flex items-center">
                        <button type="button" onClick={() => setMuted(!muted)}>
                            {muted ? <VolumeXMarkIcon className="h-4" /> : <VolumeIcon className="h-4" />}
                        </button>
                        <div className="absolute bottom-full left-1/2 -translate-x-1/2 pb-3 transition-transform scale-y-0 group-hover/volume:scale-y-100 origin-bottom">
                            <div className="w-6 rounded-md bg-neutral-100/70 flex flex-col items-center py-3">
                                <Slider direction="vertical" className="h-14" progress={volume} onChangeProgress={handleSetVolume} progressClassName="bg-neutral-900" />
                            </div>
                        </div>
                    </div>
                    {resolutions !== undefined && resolution !== undefined && onChangeResolution !== undefined && (
                        <ResolutionSelector values={resolutions} value={resolution} onChange={onChangeResolution} />
                    )}
                    <button type="button" className="hover:fill-primary-900" onClick={handleToggleFullscreen}>
                        {fullscreen ? <CompressIcon className="h-4" /> : <ExpandIcon className="h-4" />}
                    </button>
                </div>
            )}
        </div>
    )
}