import { useGlissadeButton } from "@enymo/glissade";
import { Clickable, ClickableProps } from "@enymo/react-clickable-router";
import classNames from "classnames";
import React from "react";
import Loader from "../../../components/Loader";

export interface ButtonProps extends ClickableProps {
    innerClassName?: string,
    loading?: boolean,
    variant: "primary" | "secondary" | "member" | "play" | "transparent"
}

export default function Button({
    className,
    innerClassName,
    variant,
    loading: loadingProp,
    disabled,
    onClick,
    submit,
    children,
    ...props
}: ButtonProps) {
    const {loading, ...glissade} = useGlissadeButton({disabled, loading: loadingProp, onClick, submit});

    return (
        <Clickable className={classNames("relative disabled:cursor-not-allowed", {
            "rounded-lg bg-primary-500 text-white fill-white hover:bg-primary-600 disabled:bg-neutral-600 body-l-md h-10 px-3": variant === "primary",
            "rounded-lg bg-neutral-700 hover:bg-neutral-800 body-l-md text-white fill-white h-10 px-3": variant === "secondary",
            "rounded-lg bg-neutral-900/10 hover:bg-neutral-900/20 body-l-md text-white text-opacity-75 hover:text-opacity-90 fill-white h-9 px-3": variant === "member",
            "rounded-lg bg-bg-700/90 hover:bg-bg-900/90 font-medium text-xl leading-3 text-primary-800 fill-primary-800 h-12 px-5": variant === "play",
            "rounded-lg bg-neutral-600/10 hover:bg-neutral-800/10 body-m text-text-700 fill-text-700 hover:text-text-800 h-8 px-2": variant === "transparent"
        }, className)} submit={submit} {...props} {...glissade}>
            <div className={classNames("size-full flex items-center justify-center", {"invisible": loading}, innerClassName)}>
                {children}
            </div>
            {loading && (
                <div className="absolute inset-1.5">
                    <Loader className="size-full" />
                </div>
            )}
        </Clickable>
    )
}