import { createContext } from "@enymo/react-better-context";
import { Form, SubmitHandler } from "@enymo/react-form-component";
import * as Sentry from "@sentry/react";
import React, { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Button from "./components/form/Button";
import Input from "./components/form/Input";

const [Provider, useSentryUser] = createContext<[string | null, ((user: string | null) => void) | null]>([null, null]);

interface Submit {
    message: string
}

const ErrorFallback: Sentry.FallbackRender = ({
    eventId,
    resetError
}) => {
    const {t} = useTranslation();
    const [user] = useSentryUser();
    const form = useForm<Submit>();

    const handleSubmit: SubmitHandler<Submit> = ({message}) => {
        Sentry.captureFeedback({
            associatedEventId: eventId,
            message,
            email: user ?? undefined
        });
        resetError();
    }

    return (
        <div className="grow bg-bg-700 flex justify-center items-center">
            <Form form={form} className="flex flex-col bg-bg-300 rounded-xl w-[480px] px-10 py-8" onSubmit={handleSubmit}>
                <h1 className="heading-l text-center">{t("errorFallback.title")}</h1>
                <p className="mt-11 body-m text-center">{t("errorFallback.text")}</p>
                <Input rows={5} className="mt-8" type="textarea" name="message" label={t("errorFallback.feedback")} options={{
                    required: t("errorFallback.feedback.required")
                }} placeholder={t("errorFallback.feedback.placeholder")} />
                <Button className="mt-12" variant="primary" submit>{t("errorFallback.submit")}</Button>
                <Button className="mt-5" variant="secondary" onClick={resetError}>{t("errorFallback.skip")}</Button>
            </Form>
        </div>
    )
}

const handleReset = () => {
    window.history.pushState(null, "", "/");
}

export { useSentryUser };
export default function ErrorBoundary({children}: {
    children: React.ReactNode
}) {
    const [user, setUser] = useState<string | null>(null);

    const handleSetUser = useCallback((user: string | null) => {
        Sentry.setUser(user === null ? null : {
            email: user
        });
        setUser(user);
    }, [setUser]);

    return (
        <Provider value={[user, handleSetUser]}>
            <Sentry.ErrorBoundary onReset={handleReset} fallback={ErrorFallback}>
                {children}
            </Sentry.ErrorBoundary>
        </Provider>
    )
}