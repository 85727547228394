import { GlissadeChoiceProps } from "@enymo/glissade";

export interface ChoiceProps extends GlissadeChoiceProps {
    className?: string,
    children?: React.ReactNode
}

export const locales = <const>["de", "en", "it", "fr", "es"];
export type Locale = typeof locales[number];

export const resolutions = <const>["sd", "hd", "fhd", "uhd", "fuhd"];
export type Resolution = typeof resolutions[number];

export const breakpoints = {
    '2xl': 1536,
    xl: 1280,
    lg: 1024,
    md: 768,
    sm: 640,
}
export type Breakpoint = keyof typeof breakpoints;

export type ImageSize = "full" | "preview" | "thumbnail";

export type Rating = "like" | "dislike" | null;

export const zodiacs = <const>["aries", "taurus", "gemini", "cancer", "leo", "virgo", "libra", "scorpio", "sagittarius", "capricorn", "aquarius", "pisces"];
export type Zodiac = typeof zodiacs[number];

export const nationalities = <const>["german", "czech", "polish", "thai", "american", "bulgarian", "russian", "turkish", "ukranian", "chinese"];
export type Nationality = typeof nationalities[number];

export const genitalAreaTypes = <const>["shaved", "partlyShaved", "hairy", "extremelyHairy"];
export type GenitalAreaType = typeof genitalAreaTypes[number];

export const preferences = <const>["analSex", "bdsm", "pictureExchange", "blowjob", "bondage", "lingerie", "dirtyTalk", "threesome", "eroticChat", "exhibitionism", "fetters", "fetish", "ffm", "fisting", "naturism", "photography", "footFetish", "gangbang", "facials", "groupSex", "handjob", "hardcore", "roughSex", "highHeels", "shaven", "intimateJewelry", "kamasutra", "caresses", "kissing", "rubberAndLeather", "latex", "massages", "pee", "nylons", "nymphomaniac", "outdoor", "paarsex", "piercings", "pissing", "porn", "rolePlaying", "masturbation", "sex", "sexToys", "sm", "cumSwallow", "cumSwapping", "squirting", "tantra", "tattoos", "phoneSex", "toys", "vaginal", "webcam", "watch", "lesbian", "trans", "milf"];
export type Preference = typeof preferences[number];

export const status = <const>["incomplete", "processing", "missingModels", "error", "planned", "published", "unpublished"];
export type Status = typeof status[number];
export interface Dimensions {
    width: number,
    height: number
}

export interface ListProps<T extends string, U> {
    sortBy: T,
    onChangeSortBy: (sortBy: T) => void,
    totalItems?: number,
    itemsPerPage: number,
    items?: U[],
    page: number,
    onChangePage: (page: number) => void
}

export interface Node {
    id: number | string
}

export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;
export type RequiredBy<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>;
export type MaybePromise<T> = Promise<T> | T;